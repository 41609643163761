import React from 'react'
import image4 from '../../../assets/images/story/choco/choco-cycle-time-insights.png'
import image7 from '../../../assets/images/story/choco/choco-dev-overview.png'
import image1 from '../../../assets/images/story/choco/choco-insights.png'
import image5 from '../../../assets/images/story/choco/choco-investment-balance.png'
import image6 from '../../../assets/images/story/choco/choco-pr-cycle-time.png'
import image2 from '../../../assets/images/story/choco/choco-pr.png'
import image3 from '../../../assets/images/story/choco/choco-work-log.png'
import matteo from '../../../assets/images/story/choco/matteo.png'
import vignesh from '../../../assets/images/story/choco/vignesh.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const ChocoBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Choco is an order management platform for restaurants and suppliers
            on a mission to enable a sustainable food system that runs
            transparently and without waste. Founded in 2018, the company has
            grown fast, achieved Unicorn status, and raised a total of $328
            million to support their zero-waste mission.
          </p>
        ),
        href: 'https://choco.com/',
        location: (
          <p>Headquartered in Berlin, operations in Europe and the US</p>
        ),
        headcount: <p>400+ employees, 80+ engineers</p>,
        customers: <p>2023</p>,
      }}
      main={
        <>
          <p>
            When the Choco engineering team first reached out to Swarmia in
            2022, Matteo Pescarin had recently been hired as an Engineering
            Manager, charged with setting metrics-based objectives, reducing
            waste, and improving his team’s ability to deliver at speed. Vignesh
            Rathika was a Senior Technical Program Manager in the Platform team
            where he looked after the overall productivity of the engineering
            organization.
          </p>
          <p>
            Back then, the Choco engineering organization had already laid the
            foundations for improving transparency, agreeing on what to measure
            and how, and equipping the teams with the tools they need to
            continuously improve their processes. They had previously been using
            Haystack to get rudimentary analytics from GitHub, but were getting
            increasingly frustrated about the limitations of those reports.
          </p>
          <InlineQuote
            quote="GitHub alone doesn’t always reflect the way we work. We wanted to get an aggregated data dashboard that would give us a holistic view into the engineering organization by connecting our pull requests in GitHub with our issues in Jira. Ultimately, our goal was to make engineering work more clear and transparent, and the Jira integration was a major driver for us wanting to replace Haystack."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <p>
            Additionally, Vignesh had noticed that there was a big gap between
            identifying a problem from one of the reports in Haystack and
            turning those insights into concrete action points.
          </p>
          <InlineQuote
            quote="Haystack is a reporting platform but it doesn’t give us the micro feedback loops that Swarmia does. Of course, we tried to operationalize the insights from Haystack with the engineering managers, but we quickly realized that we could automate some of that with Swarmia and take a lot of the work off our EMs’ plates."
            name="Vignesh Rathika"
            title="Senior Technical Program Manager"
            photo={vignesh}
          />
          <p>
            As the Choco engineering organization continues to hire and grow to
            support their zero food waste mission, it was also important for
            Matteo and Vignesh to find a tool that would promote their culture
            of autonomy and ownership going forward.
          </p>
          <InlineQuote
            quote="We want each of the product teams to operate in a way that fits them. The goal of the platform team is to provide them with the tools and insights they need to make sure they can solve all their problems independently. As we grow, we want the teams to be more autonomous, and Swarmia helps with that."
            name="Vignesh Rathika"
            title="Senior Technical Program Manager"
            photo={vignesh}
          />

          <h2>
            Complete visibility to every level of the engineering organization
          </h2>

          <p>
            Even though Matteo and Vignesh did a high-level comparison of other
            tools in the market, they quickly came to the conclusion that
            Swarmia would give them the holistic insights they were after.
          </p>
          <InlineQuote
            quote="Swarmia really wins on simplicity and user experience. As I’ve been using it more, I’ve found it fantastic how simple and easy the dashboards are to read at a glance. It also allows me to drill down into details."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <ProductScreenshot src={image1} />
          <p>
            One of the first concrete actions Matteo’s team took was cleaning up
            the old and stale pull requests that had fallen through the cracks
            over time.
          </p>
          <InlineQuote
            quote="At the very beginning when I started using Swarmia, I spotted a number of stale pull requests that had been sitting there for ages and that I had never seen in GitHub. We were immediately able to clean them up."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <ProductScreenshot src={image2} />
          <p>
            Early on, Matteo was also surprised by the high number of PRs that
            weren’t mapped to any issues in Jira, which made it difficult to
            understand the big picture of where engineers were spending their
            time.
          </p>
          <InlineQuote
            quote="We had a number of initiatives that we were running outside the planned roadmap, and there was no easy way to see what people were working on. We realized that with Swarmia, these things would just naturally emerge, and now we’re able to get a holistic view of what’s going on."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <p>
            In addition to looking at just one team at a time, both Vignesh and
            Matteo have really appreciated the flexibility of managing teams and
            groups of teams in Swarmia.
          </p>
          <ProductScreenshot src={image3} />
          <InlineQuote
            quote="When we’re looking at the work log, it’s absolutely fantastic that the teams are not statically computed but we’re able to form groups that are horizontal. For example, we have a specific team for the front-end group, which spans the whole organization."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <p>
            Matteo has found the advanced filters on the insights pages
            particularly useful. They allow him to drill down into problem areas
            and eliminate bottlenecks that are slowing down a large group of
            people.
          </p>
          <ProductScreenshot src={image4} />
          <InlineQuote
            quote="The drill-downs and filters, especially for cycle time data, are super useful. For example, with the front-end group, we were able to correlate real data to some of the things they were complaining about, and quickly fix an issue that was getting in their way."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <p>
            In addition to the more detailed team and group focused views,
            Matteo and Vignesh have started working on setting up investment
            categories with the goal of eventually rolling them out to the
            entire engineering organization. This will allow the enginering and
            product leadership to clearly see how engineering time is spent and
            ultimately give them the ability to quantify the value of
            engineering work to the business.
          </p>
          <ProductScreenshot src={image5} />

          <h2>
            Streamlined flow of PRs, more ownership for developers, and better
            strategic alignment
          </h2>
          <p>
            After just a few months of using Swarmia, Matteo has seen a stark
            improvement in his team’s pull request cycle time. He’s also been
            happy to see that new PRs are no longer falling through the cracks
            and that his team has started more systematically linking PRs to
            issues in Jira.
          </p>
          <InlineQuote
            quote="With the help of Swarmia, we’ve managed to keep a much closer eye on PRs in code review, the number of open PRs, and how much time we’re spending on them. These things used to be a big bottleneck for us, and we now have a better understanding of how things are going."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
          <ProductScreenshot src={image6} />
          <p>
            The engineering managers at Choco have also started socializing the
            developer overview in 1:1s with their teams to promote company’s
            culture of autonomy and ownership. The view allows developers to
            celebrate work that they’ve completed, have higher quality career
            discussions, and reflect on whether they’ve been able to focus on
            the right things.
          </p>
          <ProductScreenshot src={image7} />
          <p>
            Overall, as Choco keeps growing, Matteo and Vignesh feel like
            Swarmia will continue to act as a catalyst for strategic alignment
            and the important discussions that need to happen between the
            engineering and product organizations.
          </p>
          <InlineQuote
            quote="While we’re still in the process of agreeing on the investment categories we’d like to track across the organization in the long term, the conversations we’ve had between the engineering leaders and the product managers have already increased alignment."
            name="Matteo Pescarin"
            title="Engineering Manager"
            photo={matteo}
          />
        </>
      }
    />
  )
}
export default ChocoBody
