import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const FactorialHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="Choco"
      heading="How Choco drives transparency, autonomy, and alignment across the organization"
      subheading="The engineering and product organizations at Choco use Swarmia to gain visibility, start better conversations, and promote strategic alignment with the business."
      featured={featured}
    />
  )
}

export default FactorialHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/choco/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
