import React from 'react'
import MetaImage from '../../assets/images/story/choco/cover.png'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import ChocoBody from '../../components/story/choco/Content'
import ChocoHeader from '../../components/story/choco/Header'

const ChocoPage = () => {
  return (
    <Layout
      title="Choco case study"
      description="The engineering and product organizations at Choco use Swarmia to start better conversations and empower teams to take ownership of their product areas."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <ChocoHeader />
        <QuoteBlock person="matteo" />
        <ChocoBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default ChocoPage
